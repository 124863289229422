@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Brown';
    font-style: normal;
    font-weight: 400;
    src: local(''),
      url('./font/Brown-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Brown';
    font-style: bold;
    font-weight: 900;
    src: local(''),
      url('./font/Brown-Bold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Berlin';
    font-style: bold;
    font-weight: 900;
    src: local(''),
      url('./font/berlin.ttf');
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fade-transition {
  @apply transition duration-300 ease-in-out
}